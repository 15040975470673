<template>
  <div id="word-cloud-result-card" class="card drop-shadow-lg rounded-xl">
    <div class="card-body md:flex flex-row justify-between items-baseline">
      <h2 class="card-title">
        Conçu avec
        <svg id="icon-heart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
          />
        </svg>
        par
        <a href="https://www.codeur.com/?utm_source=nuagedemots&utm_medium=cpc&utm_campaign=pub">
          <img :src="require('../images/codeur-logo.svg')" alt="Logo de Codeur.com" />
        </a>
      </h2>
      <div class="btn-group mt-2 mt-md-0">
        <a
          ref="download-png"
          href="/nuage-de-mots.png"
          class="btn btn-sm btn-warning matomo_ignore"
          download="nuage-de-mots.png"
          :disabled="subscriptionFormVisible"
          :class="{ disabled: subscriptionFormVisible }"
          @click="download($event, 'PNG')"
        >
          Télécharger le nuage
        </a>
        <button
          id="format-selector"
          aria-expanded="false"
          aria-haspopup="true"
          :disabled="subscriptionFormVisible"
          class="btn btn-sm btn-warning dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          type="button"
        >
          <span class="sr-only">Dérouler le menu</span>
        </button>
        <div class="dropdown-menu">
          <a
            ref="download-jpg"
            class="dropdown-item matomo_ignore"
            href="/nuage-de-mots.jpg"
            download="nuage-de-mots.jpg"
            @click="download($event, 'JPG')"
          >
            JPG
          </a>
          <a
            ref="download-webp"
            class="dropdown-item matomo_ignore"
            href="/nuage-de-mots.webp"
            download="nuage-de-mots.webp"
            @click="download($event, 'WebP')"
          >
            WebP
          </a>
        </div>
      </div>
    </div>

    <div id="word-cloud-container" class="card-body">
      <transition name="fade">
        <div v-if="subscriptionFormVisible" id="word-cloud-newsletter" class="text-center flex items-center">
          <form class="w-100" @submit.prevent="subscribe">
            <h3 class="mb-3 font-weight-bold">
              Nous allons vous envoyer votre nuage au format : {{ queuedDownloadFormat }}
            </h3>
            <p class="mb-3 font-weight-bold">Entrez votre adresse mail pour recevoir votre nuage de mots</p>
            <div class="row">
              <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 ml-auto mr-auto">
                <input
                  id="email-control"
                  v-model="email"
                  type="email"
                  class="form-control"
                  placeholder="Votre adresse email"
                  :disabled="requestingSubscription"
                  :class="{ 'is-invalid': emailErrors }"
                  required
                />
                <div v-if="emailErrors" class="invalid-feedback text-left">
                  {{ emailErrors }}
                </div>
              </div>
            </div>
            <input
              type="submit"
              :disabled="requestingSubscription"
              class="btn btn-warning mt-3"
              value="Recevoir le nuage de mots"
            />
            <p class="font-italic text-secondary mt-3">
              Nous vous tiendrons au courant des autres outils gratuits (sans spam)
            </p>
          </form>
        </div>
      </transition>

      <canvas id="word-cloud" class="w-full h-auto" :class="{ grayscale: disabled }" @contextmenu.prevent=""></canvas>
    </div>
  </div>
</template>

<script>
/* global _paq */
import forIn from 'lodash/forIn'
import map from 'lodash/map'
import flatten from 'lodash/flatten'
import sumBy from 'lodash/sumBy'
import axios from 'axios'
import Cookies from 'js-cookie'

import Cloud from 'wordcloud'

export default {
  name: 'WordCloud',
  props: {
    keywords: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    font: {
      type: Object,
      default: null
    },
    orientation: {
      type: Object,
      default: null
    },
    palette: {
      type: Object,
      default: null
    },
    shape: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      email: null,
      emailErrors: null,
      subscriptionFormVisible: false,
      requestingSubscription: false,
      queuedDownloadFormat: null,
      $canvas: null
    }
  },
  watch: {
    keywords(newKeywords) {
      this.generateCloud()
    },
    font(newFont) {
      this.generateCloud()
    },
    orientation(newOrientation) {
      this.generateCloud()
    },
    palette(newPalette) {
      this.generateCloud()
    },
    shape(newShape) {
      this.generateCloud()
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.$canvas = document.getElementById('word-cloud')
      this.$canvas.width = 2088
      this.$canvas.height = 1044
      this.generateCloud()
    })
  },
  methods: {
    keywordsList() {
      let total = sumBy(this.keywords, 'occurences')
      let groupSize = Math.round(total / 5)

      const groups = []
      forIn(['xl', 'lg', 'md', 'sm', 'xs'], size => {
        groups[size] = { list: [], weight: 0, letters: 0 }
      })

      forIn(this.keywords, keyword => {
        forIn(groups, (group, key) => {
          if (group.weight < groupSize || key === 'xs') {
            group.list.push(keyword)
            group.weight += keyword.occurences
            group.letters += keyword.word.length
            return false
          }
        })
      })

      if (groups.xl.list.length > 3) {
        total = sumBy(groups.xl.list, 'occurences')
        groupSize = Math.round(total / 2)
        const keywords = groups.xl.list
        groups.vip = groups.xl = { list: [], weight: 0, letters: 0 }

        forIn(keywords, keyword => {
          forIn(['vip', 'xl'], key => {
            if (groups[key].weight < groupSize || key === 'xs') {
              groups[key].list.push(keyword)
              groups[key].weight += keyword.occurences
              groups[key].letters += keyword.word.length
              return false
            }
          })
        })
      }
      const list = []

      forIn(groups, (group, key) => {
        let weight = 2
        if (key === 'vip') {
          weight = 18
        } else if (key === 'xl') {
          weight = 12
        } else if (key === 'lg') {
          weight = 8
        } else if (key === 'md') {
          weight = 6
        } else if (key === 'sm') {
          weight = 4
        }

        list.push(
          map(group.list, kw => {
            return [kw.word, weight]
          })
        )
      })

      return flatten(list)
    },
    download(event, format) {
      // Set custom link tracking for download
      _paq.push(['trackLink', `${window.location.origin}/${event.target.download}`, 'download'])

      // Check if user is subscribed
      if (Cookies.get('_word_cloud_subscribed') !== 'true') {
        event.preventDefault()
        this.queuedDownloadFormat = format
        this.subscriptionFormVisible = true
        return
      }

      let mimeType = 'image/png'
      if (format === 'JPG') {
        mimeType = 'image/jpeg'
      } else if (format === 'SVG') {
        mimeType = 'image/svg+xml'
      } else if (format === 'WebP') {
        mimeType = 'image/webp'
      }

      const context = this.$canvas.getContext('2d')
      let url = null

      if (format === 'JPG') {
        const jpgCanvas = document.createElement('canvas')
        jpgCanvas.width = this.$canvas.width
        jpgCanvas.height = this.$canvas.height
        const jpgCanvasContext = jpgCanvas.getContext('2d')
        jpgCanvasContext.beginPath()
        jpgCanvasContext.rect(0, 0, jpgCanvas.width, jpgCanvas.height)
        jpgCanvasContext.fillStyle = '#fff'
        jpgCanvasContext.fill()
        jpgCanvasContext.drawImage(this.$canvas, 0, 0, this.$canvas.width, this.$canvas.height)
        url = jpgCanvas.toDataURL(mimeType)
      } else {
        const imageData = context.getImageData(0, 0, this.$canvas.width, this.$canvas.height)
        const newImageData = context.createImageData(imageData)

        for (let i = 0; i < imageData.data.length; i += 4) {
          newImageData.data[i] = imageData.data[i]
          newImageData.data[i + 1] = imageData.data[i + 1]
          newImageData.data[i + 2] = imageData.data[i + 2]

          if (newImageData.data[i] + newImageData.data[i + 1] + newImageData.data[i + 2] + imageData.data[i + 3] === 1020) {
            newImageData.data[i + 3] = 0
          } else {
            newImageData.data[i + 3] = imageData.data[i + 3]
          }
        }

        context.putImageData(newImageData, 0, 0)
        url = this.$canvas.toDataURL(mimeType)
      }

      if ('download' in document.createElement('a')) {
        event.target.href = url
      } else {
        event.preventDefault()
        window.open(url, '_blank', 'width=500,height=300,menubar=yes')
      }
    },
    subscribe() {
      if (this.requestingSubscription) return

      this.requestingSubscription = true
      axios
        .post('api/newsletter_subscriptions', {
          email: this.email,
          list: 'wordcloud'
        })
        .then(response => {
          this.requestingSubscription = false
          this.subscriptionFormVisible = false
          Cookies.set('_word_cloud_subscribed', 'true', { expires: 7 })
          this.$refs['download-' + this.queuedDownloadFormat.toLowerCase()].click()
        })
        .catch(error => {
          this.requestingSubscription = false
          this.emailErrors = error.response.data.errors.email[0]
        })
    },
    generateMask() {
      const image = new Image()
      image.src = 'data:image/svg+xml;base64,' + btoa(this.shape.svg)

      image.onload = () => {
        const maskCanvas = document.createElement('canvas')
        maskCanvas.width = this.$canvas.width
        maskCanvas.height = this.$canvas.height
        const maskCanvasContext = maskCanvas.getContext('2d')
        maskCanvasContext.drawImage(
          image,
          this.$canvas.width * 0.5 - this.$canvas.height * 0.5,
          0,
          this.$canvas.height,
          this.$canvas.height
        )

        const imageData = maskCanvasContext.getImageData(0, 0, maskCanvas.width, maskCanvas.height)
        const newImageData = maskCanvasContext.createImageData(imageData)

        for (let i = 0; i < imageData.data.length; i += 4) {
          newImageData.data[i] = newImageData.data[i + 1] = newImageData.data[i + 2] = 255
          newImageData.data[i + 3] = imageData.data[i + 3] < 128 ? 255 : 0
        }

        maskCanvasContext.putImageData(newImageData, 0, 0)
        const ctx = this.$canvas.getContext('2d')
        ctx.drawImage(maskCanvas, 0, 0)
      }
    },
    generateCloud(regenerateMask = false) {
      const context = this.$canvas.getContext('2d')
      context.clearRect(0, 0, this.$canvas.width, this.$canvas.height)

      this.generateMask()

      if (this.scheduledDraw) clearTimeout(this.scheduledDraw)
      this.scheduledDraw = setTimeout(this._generateCloud, 320)
    },
    _generateCloud() {
      const colors = this.palette.colors
      const rotations = this.orientation.id === 1 ? [0, 0] : [-Math.PI * 0.12, Math.PI * 0.12]

      Cloud(this.$canvas, {
        gridSize: 10,
        weightFactor: 9,
        color(word, weight) {
          return colors[Math.round(Math.random() * 3)]
        },
        fontFamily: this.font.name,
        minRotation: rotations[0],
        maxRotation: rotations[1],
        rotationSteps: 18,
        rotateRatio: 0.8,
        clearCanvas: false,
        backgroundColor: 'transparent',
        list: this.keywordsList()
      })
    }
  }
}
</script>
