// CAUTION: Do not edit this file. It has been generated automatically.
// To regenerate this file manually, run: yarn build --generate-stimulus-index
import { application } from './application'
import StimulusControllerResolver from 'stimulus-controller-resolver'
StimulusControllerResolver.install(application, async controllerName => {
  switch (controllerName) {
    case 'topbar':
      return (await import('./topbar_controller')).default
    default:
      console.error('Unknown Stimulus controller: ' + controllerName)
  }
})
