<template>
  <div class="cards h-full flex flex-col justify-between">
    <AdCardRedacteur />
    <AdCardFacture />
    <AdCardGraphiste />
    <AdCardNewsletter />
  </div>
</template>

<script>
import AdCardFacture from './AdCardFacture.vue'
import AdCardGraphiste from './AdCardGraphiste.vue'
import AdCardNewsletter from './AdCardNewsletter.vue'
import AdCardRedacteur from './AdCardRedacteur.vue'

export default {
  name: 'AdCards',
  components: {
    AdCardFacture,
    AdCardGraphiste,
    AdCardNewsletter,
    AdCardRedacteur
  }
}
</script>
