<template>
  <div class="container">
    <div class="row mb-8">
      <div class="col-12 col-lg-8">
        <div id="word-cloud-params-card" class="card drop-shadow-lg rounded-xl -mt-20">
          <div class="card-body">
            <div class="row">
              <form class="text-center w-100">
                <div class="form-group">
                  <label for="word-cloud-keywords-text">Ajoutez des mots ou collez un texte</label>
                  <div class="col-12 col-md-10 col-lg-12 col-xl-10 ml-auto mr-auto">
                    <textarea
                      id="word-cloud-keywords-text"
                      v-model="keywordsText"
                      class="form-control"
                      rows="6"
                      @focus.prevent="clearDefaultText"
                      @blur.prevent="setDefaultText"
                    ></textarea>
                    <div class="word-cloud-keywords-sticker">
                      <div class="arrow-down-sticker"></div>
                    </div>
                    <div id="word-cloud-keywords">
                      <div v-for="(keyword, index) in keywords" v-show="!emptyText" :key="index" class="badge">
                        <span class="badge-count">{{ keyword.occurences }}</span>
                        {{ keyword.word }}
                        <button type="button" @click.prevent="blacklistedKeywords.push(keyword)">&times;</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="word-cloud-shape">Choisissez une forme</label>
                  <div class="col-12 col-md-11 col-lg-12 col-xl-10 ml-auto mr-auto">
                    <label
                      v-for="shape in shapes"
                      :id="'shape-' + shape.id"
                      :key="shape.id"
                      class="btn-checkbox"
                      :class="{ active: shape.id == selectedShape }"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <div class="word-cloud-shape" v-html="shape.svg"></div>
                      <!-- eslint-enable vue/no-v-html -->
                      <input
                        v-model="selectedShape"
                        class="form-check-input"
                        type="radio"
                        name="word-cloud-shape"
                        :value="shape.id"
                        :aria-label="shape.name"
                      />
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="word-cloud-orientation">Choisissez une orientation</label>
                  <div class="col-12">
                    <label
                      v-for="orientation in orientations"
                      :id="'orientation-' + orientation.id"
                      :key="orientation.id"
                      class="btn-checkbox btn-checkbox-lg"
                      :class="{ active: orientation.id == selectedOrientation }"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <div class="word-cloud-orientation" v-html="orientation.svg"></div>
                      <!-- eslint-enable vue/no-v-html -->
                      <input
                        v-model="selectedOrientation"
                        class="form-check-input"
                        type="radio"
                        name="word-cloud-orientation"
                        :value="orientation.id"
                        :aria-label="orientation.name"
                      />
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label id="word-cloud-font-label">Choisissez une police</label>
                  <div class="col-12 col-md-10 col-lg-11 col-xl-9 ml-auto mr-auto">
                    <div class="dropdown dropdown-select">
                      <button
                        id="word-cloud-font"
                        class="btn btn-block btn-default dropdown-toggle"
                        :style="{ fontFamily: findSelected(fonts, selectedFont).name }"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ findSelected(fonts, selectedFont).name }}
                      </button>
                      <div class="dropdown-menu w-100" aria-labelledby="word-cloud-font-label">
                        <a
                          v-for="font in fonts"
                          :id="'font-' + font.id"
                          :key="font.id"
                          class="dropdown-item"
                          href="#"
                          :style="{ fontFamily: font.name }"
                          @click.prevent="selectedFont = font.id"
                        >
                          {{ font.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="word-cloud-palettes">Choisissez une couleur</label>
                  <div class="col-12 col-md-11 col-lg-12 col-xl-10 ml-auto mr-auto">
                    <label
                      v-for="palette in palettes"
                      :id="'palette-' + palette.id"
                      :key="palette.id"
                      class="btn-checkbox"
                      :class="{ active: palette.id == selectedPalette }"
                    >
                      <div class="word-cloud-palette">
                        <span
                          v-for="(color, index) in palette.colors"
                          :key="index"
                          :style="{ backgroundColor: color }"
                        ></span>
                      </div>
                      <input
                        v-model="selectedPalette"
                        class="form-check-input"
                        type="radio"
                        name="word-cloud-palette"
                        :value="palette.id"
                        :aria-label="palette.name"
                      />
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="hidden lg:block col-lg-4">
        <AdCards />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <WordCloud
          :disabled="emptyText"
          :keywords="keywords"
          :font="findSelected(fonts, selectedFont)"
          :orientation="findSelected(orientations, selectedOrientation)"
          :palette="findSelected(palettes, selectedPalette)"
          :shape="findSelected(shapes, selectedShape)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import differenceBy from 'lodash/differenceBy'
import words from 'lodash/words'
import countBy from 'lodash/countBy'
import orderBy from 'lodash/orderBy'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import forOwn from 'lodash/forOwn'
import map from 'lodash/map'

import WebFont from 'webfontloader'

import fontsJSON from './data/fonts.json'
import orientationsJSON from './data/orientations.json'
import palettesJSON from './data/palettes.json'
import shapesJSON from './data/shapes.json'

import AdCards from './components/AdCards.vue'
import WordCloud from './components/WordCloud.vue'

export default {
  name: 'Home',
  components: {
    AdCards,
    WordCloud
  },

  data() {
    return {
      defaultText: `Un nuage de mots est une représentation visuelle des mots clés contenus dans un texte. Chaque mot est représenté flottant dans l'espace. La taille de chacun des mots est proportionnelle à leur fréquence d'utilisation dans le texte.
Un nuage de mots peut être adapté esthétiquement pour prendre une forme définie : une forme géométrique comme un carré, un triangle ou un rond, ou bien une forme plus poétique comme un nuage, un cœur, une pièce de puzzle...
Les nuages de mots permettent de visualiser rapidement les sujets abordés dans un texte et de mettre en évidence les mots clés les plus importants. Ils sont utilisés en analyse de données textuelles, pour identifier les thèmes qui ressortent le plus dans des textes, ou encore pour illustrer des sites internet, des présentations ou tout autre texte nécessitant de mettre en valeur ses mots-clés.`,
      keywordsText: '',
      blacklistedKeywords: [],
      fonts: fontsJSON,
      selectedFont: 10,
      orientations: orientationsJSON,
      selectedOrientation: 2,
      palettes: palettesJSON,
      selectedPalette: 12,
      shapes: shapesJSON,
      selectedShape: 9
    }
  },

  computed: {
    keywords() {
      let arr = []
      let text = this.keywordsText
      if (!text) {
        text = this.defaultText
      }

      forOwn(countBy(words(text), String.toLowerCase), (count, word) => {
        arr.push({ word, occurences: count })
      })

      arr = differenceBy(arr, this.blacklistedKeywords, 'word')
      return orderBy(arr, ['occurences'], ['desc'])
    },
    emptyText() {
      return words(this.keywordsText).length === 0
    }
  },

  created() {
    WebFont.load({
      events: false,
      class: false,
      google: { families: map(this.fonts, 'name') }
    })

    const ignoreWords = `pour que qui qu quoi ou par puis mais or car donc ni ne n alors
                       afin ou où de des du dû d dès l la le les sa son ça se ce c s si ses ces a à
                       cela je tu il ils elle elles nous vous dans un une en et
                       va vont votre vos ton ta t leur leurs au aux est pas tout tous
                       sur ainsi voici avec jusqu sera seront y même`

    forEach(words(ignoreWords), word => {
      this.blacklistedKeywords.push({ word, occurences: 0 })
    })
    this.keywordsText = this.defaultText
  },

  methods: {
    clearDefaultText() {
      if (this.keywordsText === this.defaultText) this.keywordsText = ''
    },
    setDefaultText() {
      if (this.emptyText) this.keywordsText = this.defaultText
    },
    findSelected(collection, id) {
      return find(collection, { id })
    },
    randomSelectFrom(collection) {
      return collection[Math.round(Math.random() * (collection.length - 1))].id
    }
  }
}
</script>
