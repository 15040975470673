<template>
  <div class="card text-white bg-dark card-newsletter rounded-xl border-none">
    <div class="card-body">
      <p class="text-center">
        Boostez votre stratégie marketing et développez votre chiffre d'affaires avec les conseils des spécialistes
        Codeur.com
      </p>
      <div class="row">
        <ul class="list-unstyled mx-auto col-xl-10">
          <li>
            <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Conversion e-commerce
          </li>
          <li>
            <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z"
              />
            </svg>
            Tendances mobile
          </li>
          <li>
            <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Optimisation webmarketing
          </li>
        </ul>
      </div>
      <form v-if="!subscribed" @submit.prevent="subscribe">
        <input
          v-model="email"
          :class="{ 'is-invalid': emailErrors }"
          :disabled="requestingSubscription"
          name="email"
          type="email"
          placeholder="votre adresse email"
          class="form-control text-center"
          required
        />
        <div v-if="emailErrors" class="invalid-feedback">
          {{ emailErrors }}
        </div>
        <input
          :disabled="requestingSubscription"
          type="submit"
          value="Recevoir des conseils"
          class="btn btn-warning btn-block mt-2"
        />
      </form>
      <p v-else class="text-success text-center mb-0">Vous êtes inscrit à la newsletter.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'AdCardNewsletter',
  data() {
    const subscribed = Cookies.get('_newsletter_subscribed') === 'true'

    return {
      email: null,
      emailErrors: null,
      subscribed,
      requestingSubscription: false
    }
  },
  methods: {
    subscribe() {
      this.requestingSubscription = true

      axios
        .post('api/newsletter_subscriptions', {
          email: this.email,
          list: 'codeur'
        })
        .then(response => {
          this.emailErrors = null
          this.subscribed = true
          this.requestingSubscription = false
          Cookies.set('_newsletter_subscribed', 'true', {
            expires: 7
          })
        })
        .catch(error => {
          this.requestingSubscription = false
          this.emailErrors = error.response.data.errors.email[0]
        })
    }
  }
}
</script>
