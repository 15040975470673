<template>
  <div class="card card-graphiste text-center rounded-xl">
    <img :src="require('../images/graphiste-logo.svg')" alt="Logo de Graphiste.com" class="card-img-top" />
    <div class="card-body">
      <h3>Besoin d'un graphiste ?</h3>
      <p>Des milliers de graphistes freelance sont disponibles pour réaliser tous vos travaux graphiques.</p>
      <a
        href="https://graphiste.com/projects/new?utm_source=nuagedemots&amp;utm_medium=cpc&amp;utm_campaign=pub"
        class="btn btn-graphiste"
      >
        Déposer une annonce
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdCardGraphiste'
}
</script>
