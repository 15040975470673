<template>
  <div class="card card-facture text-white text-center rounded-xl">
    <img :src="require('../images/facture-logo.svg')" alt="Logo de Facture.net" class="card-img-top" />
    <div class="card-body">
      <span>Facturation et CRM 100% gratuit</span>
    </div>
    <div class="card-body card-body-img drop-shadow-md">
      <img :src="require('../images/facture-hero.jpg')" alt="Capture d'écran de Facture.net" class="img-fluid rounded-sm" />
    </div>
    <div class="card-body">
      <a href="https://www.facture.net/?utm_source=nuagedemots&utm_medium=cpc&utm_campaign=sidebar" class="btn btn-facture">
        Créer un compte
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdCardFacture'
}
</script>
