<template>
  <div class="card card-redacteur text-center rounded-xl">
    <img :src="require('../images/redacteur-logo.png')" alt="Logo de Redacteur.com" class="card-img-top" />
    <div class="card-body">
      <h3>Besoin d'un rédacteur web ?</h3>
      <p>Confiez la rédaction de vos contenus à nos rédacteurs professionnels</p>
      <a
        href="https://www.redacteur.com/steps/orders/new?utm_source=nuagedemots&amp;utm_medium=cpc&amp;utm_campaign=pub"
        class="btn btn-redacteur"
      >
        Commandez vos textes
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdCardRedacteur'
}
</script>
