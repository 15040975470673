// Entry point for the build script in your package.json

import { Turbo } from '@hotwired/turbo-rails'

// import '@/initializers'

// Load Stimulus controllers
import '@/controllers'

import 'bootstrap/js/src/util'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
import '@/home'

window.Turbo = Turbo
